<template>
	<div id="Strategy" class="height85vh">
		<div class="StrategyLeft">
            <div class="StrategyLeftTop">
                <div class="StrategyLeftTopLeft">
                    {{$store.state.teachlanguagedata.Policylist}}
                </div>
                <div class="StrategyLeftTopRight">
                    {{$store.state.teachlanguagedata.Addpolicy}}
                </div>
            </div>
            <div  class="StrategyLeftCon">
                <div v-for="item in [{name:'课中策略'}]">
                    <span>{{item.name}}</span>
                    <span></span>
                </div>
            </div>
        </div>
		<div class="StrategyRight"></div>
	</div>
</template>
<script>
	export default {
		name: 'App',
		data() {
			return {
			}
		},
	}
</script>
<style lang="scss">
    #Strategy{
        margin: 20px 40px;
        display:flex;
        background:#fff;
        .StrategyLeft{
            width:20%;
            border-right:1px solid  #D8DEEA;
            .StrategyLeftTop{
                display:flex;
                justify-content: space-around;
                height:47px;
                align-items: center;
                border-bottom: 1px solid #D8DEEA ;
                .StrategyLeftTopLeft{
                    font-size: 16px;
                    font-weight: 500;
                    color: #071737;
                }
                .StrategyLeftTopRight{
                    font-weight: 400;
                    color: #19409E;
                    font-size: 14px;
                }
            }
        }
        .StrategyRight{
            width:80%;
        }
    }
</style>
